<template>
  <v-container fluid>
    <v-card flat>
      <v-tabs vertical class="my-tabs">
        <v-tab> <v-icon left> mdi-account-group </v-icon>Groups </v-tab>
        <v-tab> <v-icon left> mdi-sitemap-outline </v-icon>processus </v-tab>
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :title="'Groups'"
                :list="group_list"
                :qDelete="Qgroupdelete"
                :qCreate="Qgroupcreate"
                :qUpdate="Qgroupupdate"
                :headers="groupheaders"
                :add="true"
                :del="true"
                :showstd="true"
                :Get_suf="'WftGroup'"
                :master="true"
                :Update="true"
                :del_disable="group.membercount > 0"
                :key="kgroup"
                @rowselect="RowGroupSelect"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :title="'Members'"
                :list="members_list"
                :qDelete="Qmemberdelete"
                :qCreate="Qmembercreate"
                :qUpdate="Qmemberupdate"
                :headers="memberheaders"
                :add="true"
                :del="true"
                :showstd="true"
                :Get_suf="'WftGroupMember'"
                :add_disable="group.id != null"
                :master="true"
                :Update="true"
                :key="kmember"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="4" sm="4" md="4">
              <listitems
                :title="'Processus'"
                :list="processus_list"
                :qDelete="Qprocessusdelete"
                :qCreate="Qprocessuscreate"
                :qUpdate="Qprocessusupdate"
                :headers="processusheaders"
                :add="true"
                :del="true"
                :showstd="true"
                :Get_suf="'WftProcessus'"
                :master="false"
                :Update="true"
                :key="kprocessus"
                @rowselect="RowProcessusSelect"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import CREATE_GROUP from "../graphql/Dictionnary/wft/WFT_CREATE_GROUP.gql";
import UPDATE_GROUP from "../graphql/Dictionnary/wft/WFT_UPDATE_GROUP.gql";
import DELETE_GROUP from "../graphql/Dictionnary/wft/WFT_DELETE_GROUP.gql";

import CREATE_GROUP_MEMBER from "../graphql/Dictionnary/wft/WFT_CREATE_GROUP_MEMBER.gql";
import UPDATE_GROUP_MEMBER from "../graphql/Dictionnary/wft/WFT_UPDATE_GROUP_MEMBER.gql";
import DELETE_GROUP_MEMBER from "../graphql/Dictionnary/wft/WFT_DELETE_GROUP_MEMBER.gql";

import CREATE_PROCESSUS from "../graphql/Dictionnary/wft/WFT_CREATE_PROCESSUS.gql";
import UPDATE_PROCESSUS from "../graphql/Dictionnary/wft/WFT_UPDATE_PROCESSUS.gql";
import DELETE_PROCESSUS from "../graphql/Dictionnary/wft/WFT_DELETE_PROCESSUS.gql";

import DATA from "../graphql/Dictionnary/wft/DATA.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
  },
  props: {
    users: Array,
  },
  data() {
    return {
      group_list: [],
      members_list: [],
      processus_list: [],
      kgroup: 1200,
      kprocessus: 1300,
      kmember: 1400,
      group: {},
      processus: {},
      processusheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Name",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Name required"],

          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],
      groupheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Name",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Name required"],

          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Description",
          value: "description",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],
      memberheaders: [
        {
          value: "group_id",
          edit: true,
          hiden: "true",
          default: "",
        },
        {
          text: "N°",
          value: "no",
          selected: true,
        },

        {
          text: "Member",
          value: "user_id",
          item_text: "name",
          item_value: "id",
          edit: true,
          slot: "simple-select",
          type: "select",
          selected: true,
          select_list: [],
          show_list: [],
          rules: [(v) => !!v || "Member required"],
          sm: 8,
          md: 8,
        },
      ],
    };
  },
  mounted() {
    this.memberheaders[2].select_list = this.users;
    this.memberheaders[2].show_list = this.users;
    this.refresh();
  },
  computed: {
    Qgroupcreate() {
      return CREATE_GROUP;
    },
    Qgroupupdate() {
      return UPDATE_GROUP;
    },
    Qgroupdelete() {
      return DELETE_GROUP;
    },
    Qmembercreate() {
      return CREATE_GROUP_MEMBER;
    },
    Qmemberupdate() {
      return UPDATE_GROUP_MEMBER;
    },
    Qmemberdelete() {
      return DELETE_GROUP_MEMBER;
    },
    Qprocessuscreate() {
      return CREATE_PROCESSUS;
    },
    Qprocessusupdate() {
      return UPDATE_PROCESSUS;
    },
    Qprocessusdelete() {
      return DELETE_PROCESSUS;
    },
  },
  methods: {
    async refresh() {
      this.loading = true;
      let r = await this.$requette(DATA);
      if (r) {
        console.log(r);
        this.group_list = r.data.getWftGroups;
        this.processus_list = r.data.getWftProcessus;
        this.kgroup++;
      }
      this.loading = false;
    },
    RowGroupSelect(item) {
      this.group = item;
      this.memberheaders[0].default = this.group.id;
      this.members_list = item.members;
      this.kmember++;
    },
    RowProcessusSelect(item) {
      this.processus = item;
    },
  },
};
</script>
